import ResponsiveAppBar from './components/AppBar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Terms from "./pages/Terms";
import Contact from './pages/Contact';
import Services from './pages/Services';
import Search from './pages/Search';
import Home from './pages/Home';
import ReactGA from 'react-ga4';

const App = () => {
  if (window.location.host === "localhost:8080" || window.location.host === "prestige-post-pub-staging-5oy34.ondigitalocean.app") {
    ReactGA.initialize("G-Z3DQ2Q7YE1")
  } else {
    ReactGA.initialize("G-P2MF4C95L8")
  }

  return (
    <div>
      <ResponsiveAppBar></ResponsiveAppBar>
      <div style={{
          textAlign: 'center',
          margin: 'auto 3% 10% 3%'}}>

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/terms-of-use" element={<Terms/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/search" element={<Search/>}/>
          </Routes>
        </BrowserRouter>
        <div style={{display: 'none'}}>App version {process.env.REACT_APP_VERSION}</div>
      </div>
    </div>
  );
}

export default App;
