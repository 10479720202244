import { Outlet } from "react-router-dom";

const Terms = (props) => {
  return (
    <>
    <h1>Terms of Use</h1>
    <p><b>BY USING THIS SITE YOU AGREE TO THE FOLLOWING:</b></p>

    <p><b>For the security protection of this website and courtesy to other patrons, scripting and any other scripting-related activities are <b style={{color:"red"}}>strictly prohibited</b> on this site. Any user accessing the site with massive data requests that contain attributes or strings that are designated as scripting will be permanently restricted.</b></p>

    <p>The sale information being made available to you through this website is displayed as a courtesy only and each person obtaining information from this website will be bound by this disclaimer. Prestige Posting and Publishing (“PPP”) is under no obligation to provide information regarding a particular sale at any time prior to announcing the opening bid at the sale site. We reserve the right to discontinue providing the information at any time without notice and to impose limitations on access or information.</p>

    <p>We make no warranties or guarantees of any kind with respect to the accuracy or completeness of the information provided on this website. This information is subject to change at anytime. It will be necessary for you to attend all sales in order to obtain the most current information. Neither PPP, their clients or their agents will be liable for any loss or damages or any kind, including but not limited to claims for loss of profits, investments, savings, incidental or consequential damages arising from viewing or using this information. All access and use is at the risk of the user. By using this site you agree to these terms.</p>

    <p>For foreclosure sale auctions conducted in CALIFORNIA on or after January 1, 2021 with respect to properties containing from one to four residential units, the post-auction bid process described in Civil Code section 2924m may apply. Pursuant to the Civil Code, if you think you may qualify as an “eligible tenant buyer” or “eligible bidder,” you should consider contacting an attorney or appropriate real estate professional immediately for advice regarding the potential right to purchase. All bidders for property subject to Civil Code section 2924m should be aware that there is the potential for the sale auction bidding process to be extended for up to 45 days following the sale auction, and Civil Code section 2924m does not require payment of interest on any bid funds remitted to the foreclosure trustee during the time funds are held by the foreclosure trustee. The disclosure of sale information on this website during the 45-day period following the sale auction as described in Civil Code section 2924m does not constitute a representation that the post-auction bid process in Civil Code section 2924m does apply to this property. The post-auction bid process in Civil Code section 2924m does not apply unless the property subject to sale contains one to four residential units and does not apply if a prospective owner occupant is the high bidder at the sale auction pursuant to Civil Code section 2924m(c)(1).</p>
    <br></br>
    <Outlet />
  </>
  );
}

export default Terms;
