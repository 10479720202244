import { Outlet } from "react-router-dom";

const Services = (props) => {
  return (
    <>
    <h1>Services</h1>
      <h2>Comprehensive Foreclosure Notice and Sales Services</h2>
        <p>At Prestige Post & Pub, we specialize in the posting, publication, and management of non-judicial foreclosure notices and sales. Our services ensure compliance with all legal requirements, providing you with peace of mind and streamlining your foreclosure processes.</p>

      <h2>Posting Services</h2>
        <p>We manage the physical posting of foreclosure notices on properties, ensuring compliance with all legal requirements. Our process includes verifying the correct location and timing of postings, providing a hassle-free experience for you.</p>

      <h2>Publication Services</h2>
        <p>Our publication services ensure that foreclosure notices are published in newspapers and other approved publications in accordance with state laws and regulations. We prioritize accuracy and timeliness in all our publications.</p>

      <h2>Foreclosure Sales Management</h2>
        <p> Our team ensures that the sales process is conducted smoothly, adhering to all legal standards and minimizing risks.</p>

      <h2>Compliance and Verification</h2>
        <p>We place a strong emphasis on compliance, conducting thorough verification to ensure that all postings, publications, and sales meet legal standards. This approach reduces the risk of delays and legal challenges, ensuring a smooth process for you.</p>

      <h1>Why Work with Us?</h1>
        <p>Expert Knowledge: Our team has extensive experience in the legal requirements for non-judicial foreclosure notices and sales.</p>
        <p>Timely Execution: We prioritize quick turnaround times while maintaining high standards of accuracy.</p>
        <p>Attention to Detail: We meticulously verify every notice and manage every sale for compliance, providing you with confidence and peace of mind.</p>

      <h2>Partner with Prestige Post & Pub Today</h2>
        <p>Trust Prestige Post & Pub for your foreclosure posting, publication, and sales needs. <a href="/contact">Contact us</a> to learn more about our services and how we can help you achieve seamless, compliant foreclosure processes.</p>
    <Outlet />
  </>
  );
}

export default Services;
