import { Outlet } from "react-router-dom";

const Home = (props) => {
  return (
    <>
    <h1>Welcome to Prestige Post & Pub</h1>
      <p>At Prestige Post & Pub, we are committed to providing comprehensive and meticulous services related to the posting, publication, and management of non-judicial foreclosure notices and sales. Based in Santa Ana, California, our expert team ensures that all legal requirements are met with precision and efficiency. Whether you are a lender, servicer, or trustee, we are your reliable partner in managing the complexities of foreclosure processes.</p>
    <h2>Why Choose Prestige Post & Pub?</h2>
      <p>Expertise: Our team possesses deep knowledge and experience in handling foreclosure notices and sales.</p>
      <p>Efficiency: We ensure timely and accurate postings, publications, and sales management to keep your processes streamlined.</p>
      <p>Reliability: We adhere to all legal standards, minimizing your risks and ensuring compliance.</p>
    <h2>Get Started Today</h2>
      <p><a href="/contact">Contact us</a> to discover how Prestige Post & Pub can support your foreclosure notice and sales needs. Trust us to handle the details so you can focus on what matters most to your business.</p>
    <Outlet />
  </>
  );
}

export default Home;