import { Outlet } from "react-router-dom";

const Contact = (props) => {
  return (
    <>
    <h1>Reach Out to Prestige Post & Pub</h1>
      <p>We are here to assist you. If you have any questions or need support, please get in touch using the contact information below. Our team is committed to providing prompt and helpful responses.</p>
      <p>Phone: <a href="tel:9494272010,1053"> (949) 427-2010 ext.1053</a></p>
      <p>Email: <a href="mailto:Contactus@prestigepostandpub.com">Contactus@prestigepostandpub.com</a></p>
    <Outlet />
  </>
  );
}

export default Contact;
